import {  SendWXMsg, GetItemStr3, KeepZX} from '@/components/SharedVues/Shared0.js'

function CurrByEndProcess(that, ItemType, ItemCode, ItemName, CurrYYDoc, CurrBYObj, N) {
    let ZYUrlSuffix = ' \n[ MyUrlHere ]MyUrlHereMyUrl=' +  'LandTo=HistoryZYQWERItemCode=' + ItemCode + 'QWERCurrYYId=' + CurrYYDoc._id + '___' + 'MyUrl=查看';
    let BYUrlSuffix = ' \n[ MyUrlHere ]MyUrlHereMyUrl=' +  'LandTo=HistoryBYQWERItemCode=' + ItemCode + 'QWERCurrYYId=' + CurrYYDoc._id + '___' + 'MyUrl=查看';

    function TemplateObj2(Str32) {
        return {
            TemplateIndex: 2,
            Data: {
                character_string8: {value: ItemCode + '>' + CurrYYDoc.MeetTimeStr},//订单编号
                thing11: {value: GetItemStr3(ItemCode + ":" + ItemName)},//消费项目
                thing10: {value: CurrYYDoc.zyUserName + ":" + CurrBYObj._id},//用户名称
                thing26: {value: Str32}//报单档口
            }
        }
    }

    let ZG = CurrBYObj.zg, aStr1 = '', aStr2 = '';
    if (CurrBYObj.xy === '暂拒' || CurrBYObj.xy === '拉黑') {//若被约有出场费,应退还给主约
        if (CurrBYObj.xy === '暂拒') {
            aStr1 = GetItemStr3(ItemCode + ":" + ItemName) + '☞您因「' + CurrBYObj.zg + '」' + CurrBYObj.xy + '了' + CurrYYDoc.zyUserName + '。';
            aStr2 = '被约『' + CurrBYObj._id + '』因「' + CurrBYObj.zg + '」' + CurrBYObj.xy + '了您。';
        }
        if (CurrBYObj.xy === '拉黑') {
            aStr1 = GetItemStr3(ItemCode + ":" + ItemName) + '☞您拉黑了『' + CurrYYDoc.zyUserName + '』。';
            aStr2 = '被约『' + CurrBYObj._id + '』拉黑了您。';
        }
        SendWXMsg(CurrYYDoc.zyUserName, aStr1, TemplateObj2(aStr2));
        if (CurrBYObj.AppearanceFee > 0) KeepZX(that, CurrYYDoc.zyUserName, '收', CurrBYObj.AppearanceFee, "被约" + CurrBYObj.xy + CurrYYDoc.zyUserName + "退还尊享", function () {
        })
    } else if (CurrBYObj.z_bylyqk === '被约爽约' && CurrBYObj.AppearanceFee > 0)
        KeepZX(that, CurrYYDoc.zyUserName, '收', CurrBYObj.AppearanceFee, "被约爽约,退还主约尊享", function () {
        });
    else {
        if (CurrBYObj.AppearanceFee > 0) {//对抗竞技频道，主约获胜无需支付尊享，被约获胜才需要支付尊享，其它频道只要履约就能获取尊享
            if (ItemCode.charAt(0) === 'A') {
                if (ZG === '主约胜') {
                    aStr2 = '对抗竞技频道主约获胜，无需再支付尊享';
                    SendWXMsg(CurrYYDoc.zyUserName, GetItemStr3(ItemCode + ":" + ItemName) + '☞' + aStr2 + ZYUrlSuffix, TemplateObj2(aStr2));
                    SendWXMsg(CurrBYObj._id, GetItemStr3(ItemCode + ":" + ItemName) + '☞' + aStr2 + BYUrlSuffix, TemplateObj2(aStr2));
                }
                if (ZG === '被约胜') {
                    KeepZX(that, CurrBYObj._id, '收', CurrBYObj.AppearanceFee-2, "被约获胜,收取尊享", function () {
                    });
                    aStr2 = '被约『' + CurrBYObj._id + '』获胜,支付尊享:' + (CurrBYObj.AppearanceFee-2) + "个";
                    SendWXMsg(CurrYYDoc.zyUserName, GetItemStr3(ItemCode + ":" + ItemName) + '☞' + aStr2 + ZYUrlSuffix, TemplateObj2(aStr2));
                    aStr2 = '被约获胜，收取『' + CurrYYDoc.zyUserName + '』尊享:' + CurrBYObj.AppearanceFee + "个";
                    SendWXMsg(CurrBYObj._id, GetItemStr3(ItemCode + ":" + ItemName) + '☞' + aStr2 + BYUrlSuffix, TemplateObj2(aStr2));
                }
                if (ZG === '平局') {
                    aStr2 = '主被约打了个平局!';
                    SendWXMsg(CurrYYDoc.zyUserName, GetItemStr3(ItemCode + ":" + ItemName) + '☞' + aStr2 + ZYUrlSuffix, TemplateObj2(aStr2));
                    SendWXMsg(CurrBYObj._id, GetItemStr3(ItemCode + ":" + ItemName) + '☞' + aStr2 + BYUrlSuffix, TemplateObj2(aStr2));
                }
            } else {
                KeepZX(that, CurrBYObj._id, '收', CurrBYObj.AppearanceFee-2, "被约履约,挣取尊享", function () {
                });
                aStr2 = '您履约了，挣取尊享:' + (CurrBYObj.AppearanceFee-2) + "个";
                SendWXMsg(CurrBYObj._id, GetItemStr3(ItemCode + ":" + ItemName) + '☞' + aStr2 + BYUrlSuffix, TemplateObj2(aStr2));
                aStr2 = '非竞技项目被约履约，支付尊享:' + (CurrBYObj.AppearanceFee-2) + "个";
                SendWXMsg(CurrYYDoc.zyUserName, GetItemStr3(ItemCode + ":" + ItemName) + '☞' + aStr2 + ZYUrlSuffix, TemplateObj2(aStr2));
            }
        } else {
            aStr2 = '主约取消了本次邀约！';
            if (N === 1) {
                aStr2 = '项目邀约活动已结束！';
                SendWXMsg(CurrBYObj._id, GetItemStr3(ItemCode + ":" + ItemName) + '☞' + aStr2 + BYUrlSuffix, TemplateObj2(aStr2));
            } else SendWXMsg(CurrBYObj._id, GetItemStr3(ItemCode + ":" + ItemName) + '☞' + aStr2, TemplateObj2(aStr2));
        }
    }
}

export {CurrByEndProcess}